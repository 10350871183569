import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {Store} from "@ngrx/store";
import {messagesSeenRequestStart} from "@revolve-app/app/core/features/chat/store/message/message.actions";
import {ChatMemberRole, RoomType} from "@revolve/meta-data/roots/chat-microservice/metadata";

@Component({
  selector: 'app-message-item',
  standalone: true,
  templateUrl: './message-item.component.html',
  imports: [
    NgClass,
    NgIf
  ]
})
export class MessageItemComponent implements OnInit, OnChanges{
  fromMe = false;
  @Input() message: any;
  @Input() currentChatMember: any;
  @Input() chatMembers: any;
  sender: any = {}
  constructor(private store: Store) {
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['currentChatMember']?.currentValue){
      this.currentChatMember = changes['currentChatMember'].currentValue;
      if(changes['currentChatMember'].currentValue?._uuid === this.message?.senderId){
        this.fromMe = true
      }
      if(this.chatMembers){
        this.sender = this.chatMembers.find((x: any) => x._uuid === this.message?.senderId)
      }
      this.checkSeen()
    }
  }

  checkSeen(){
    if(this.currentChatMember){
      let index = this.message?.seenUserIds.indexOf(this.currentChatMember._uuid)
      if(index === -1){
        this.store.dispatch(messagesSeenRequestStart({id: this.message._uuid, roomId: this.message.roomUUID}))
      }
    }

  }

  protected readonly ChatMemberRole = ChatMemberRole;
  protected readonly RoomType = RoomType;
}
