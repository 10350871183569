import { createAction, props } from '@ngrx/store';
import {OpenRoomResponseInterface} from "@revolve/meta-data/roots/chat-microservice/room/response";
import {OpenRoomInterface} from "@revolve/meta-data/roots/chat-microservice/room/request";
import {
  MESSAGE_LIST_FILTER_RESET,
  MESSAGE_LIST_FILTER_UPDATE, MESSAGE_LIST_PAGINATION_UPDATE
} from "@revolve-app/app/core/features/chat/store/message/message.actions";

//actions for accounts store
export const ROOMS_LIST_REQUEST_START_ACTION = '[CHAT_ROOMS] list request start';
export const ROOMS_LIST_REQUEST_START_ACTION_FAIL = '[CHAT_ROOMS] list request fail';
export const ROOMS_LIST_REQUEST_SUCCESS_ACTION = '[CHAT_ROOMS] list request success';
export const OPEN_ROOM_REQUEST_START_ACTION = '[CHAT_ROOMS] open room start';
export const OPEN_ROOM_BY_ID_REQUEST_START_ACTION = '[CHAT_ROOMS] open room by id start';
export const OPEN_ROOM_REQUEST_START_ACTION_FAIL = '[CHAT_ROOMS] open room request fail';
export const OPEN_ROOM_REQUEST_SUCCESS_ACTION = '[CHAT_ROOMS] open room request success';
export const ROOMS_LIST_REQUEST_REJECT_ACTION = '[CHAT_ROOMS] open room request reject';

export const DELETE_ROOM_REQUEST_START_ACTION = '[CHAT_ROOMS] delete room request start';
export const DELETE_ROOM_REQUEST_SUCCESS_ACTION = '[CHAT_ROOMS] delete room request success';
export const DELETE_ROOM_REQUEST_REJECT_ACTION = '[CHAT_ROOMS] delete room request fail';

export const NEW_MESSAGE_ACTION = '[CHAT_ROOMS] update room counter';
export const ROOM_SEEN_MESSAGE_ACTION = '[CHAT_ROOMS] seen room';
export const SEEN_MESSAGES_FOR_ROOM_COUNTER_ACTION = '[CHAT_ROOMS] update room counter';
export const SET_MESSAGES_COUNT_ACTION = '[CHAT_ROOMS] update room counter';
export const CHANGE_ROOM_MESSAGES_COUNT_ACTION = '[CHAT_ROOMS] update room not seen messages count';

export const ROOMS_SET_SEARCH_ACTION = '[CHAT_ROOMS] set files search';
export const ROOMS_RESET_SEARCH_ACTION = '[CHAT_ROOMS] reset files search';
export const CHOOSE_ROOM_ACTION = '[CHAT_ROOMS] select room';
export const UPDATE_ROOM_ACTION = '[CHAT_ROOMS] update room';

export const ROOM_LIST_FILTER_UPDATE = '[ROOM] list filter update';
export const ROOM_LIST_FILTER_RESET = '[ROOM] list filter reset';
export const ROOM_LIST_PAGINATION_UPDATE = '[ROOM] list pagination update';
export const ROOM_ADD_ROOM = '[ROOM] add room';


export const deleteRoomRequestStart = createAction(
  DELETE_ROOM_REQUEST_START_ACTION,
  props<{id: string}>()
);

export const deleteRoomRequestSuccess = createAction(
  DELETE_ROOM_REQUEST_SUCCESS_ACTION
);

export const deleteRoomRequestReject = createAction(
  DELETE_ROOM_REQUEST_REJECT_ACTION
);

export const roomsNewMessage = createAction(
  NEW_MESSAGE_ACTION,
  props<{roomId: string, count: number, newMessage?: any, chatMemberId: string}>()
)
export const roomSeenMessage = createAction(
  ROOM_SEEN_MESSAGE_ACTION,
  props<{roomId: string, seenMemberId: string, messageId: string}>()
)

export const onUpdateRoom = createAction(
  UPDATE_ROOM_ACTION,
  props<{room?: any}>()
)

export const seenMessagesForRoomCounter = createAction(
  SEEN_MESSAGES_FOR_ROOM_COUNTER_ACTION,
  props<{id: string}>()
);

export const changeSeenMessagesCount = createAction(
  SET_MESSAGES_COUNT_ACTION,
  props<{count: number}>()
);

export const changeRoomSeenMessagesCount = createAction(
  CHANGE_ROOM_MESSAGES_COUNT_ACTION,
  props<{count: number, chatMemberId: string, roomId: string}>()
);

export const roomsListRequestStart = createAction(
  ROOMS_LIST_REQUEST_START_ACTION,
  props<{queryParams?: any}>()
);

export const roomsListRequestStartFail = createAction(
  ROOMS_LIST_REQUEST_START_ACTION_FAIL
);

export const roomsListRequestSuccess = createAction(
  ROOMS_LIST_REQUEST_SUCCESS_ACTION,
  props<{payload:any}>()
);

export const roomsListRequestFail = createAction(
  ROOMS_LIST_REQUEST_REJECT_ACTION
);

export const roomsSearchSetSearch = createAction(
  ROOMS_SET_SEARCH_ACTION,
  props<{key: string}>()
);

export const roomsResetSearch = createAction(
  ROOMS_RESET_SEARCH_ACTION
);


export const openRoomRequestStart = createAction(
  OPEN_ROOM_REQUEST_START_ACTION,
  props<{data: OpenRoomInterface, callBack?: () => void}>()
);

export const openRoomByIdRequestStart = createAction(
  OPEN_ROOM_BY_ID_REQUEST_START_ACTION,
  props<{ id: string }>()
);

export const openRoomRequestStartFail = createAction(
  OPEN_ROOM_REQUEST_START_ACTION_FAIL
);

export const openRoomRequestSuccess = createAction(
  OPEN_ROOM_REQUEST_SUCCESS_ACTION,
  props<{payload: OpenRoomResponseInterface}>()
);

export const roomListFilterUpdate = createAction(
  ROOM_LIST_FILTER_UPDATE,
  props<{payload: any}>()
);
export const roomListFilterReset = createAction(
  ROOM_LIST_FILTER_RESET
);
export const roomListPaginationUpdate = createAction(
  ROOM_LIST_PAGINATION_UPDATE,
  props<{payload: any}>()

);

export const onAddRoom = createAction(
  ROOM_ADD_ROOM,
  props<{payload: any}>()

);
