import { createAction, props } from '@ngrx/store';

//actions for message store
export const MESSAGES_LIST_REQUEST_START_ACTION = '[MESSAGES] list request start';
export const MESSAGES_LIST_REQUEST_SUCCESS_ACTION = '[MESSAGES] list request success';
export const MESSAGES_LIST_REQUEST_REJECT_ACTION = '[MESSAGES] list request reject';

export const DELETE_MESSAGE_REQUEST_START_ACTION = '[MESSAGES] delete message request start';
export const DELETE_MESSAGE_REQUEST_SUCCESS_ACTION = '[MESSAGES] delete message request success';
export const DELETE_MESSAGE_REQUEST_REJECT_ACTION = '[MESSAGES] delete message request fail';

export const SEND_NEW_MESSAGE_START = '[MESSAGES] sen new message request start';
export const SEND_NEW_MESSAGE_SUCCESS_ACTION = '[MESSAGES] sen new message request success';
export const ON_NEW_MESSAGE = '[MESSAGES] on new message';
export const SEEN_MESSAGES_FOR_ROOM_COUNTER_ACTION = '[MESSAGES] update message counter';
export const SET_MESSAGES_COUNT_ACTION = '[MESSAGES] update message counter';

export const MESSAGE_LIST_FILTER_UPDATE = '[MESSAGES] list filter update';
export const MESSAGE_LIST_FILTER_RESET = '[MESSAGES] list filter reset';
export const MESSAGE_LIST_PAGINATION_UPDATE = '[MESSAGES] list pagination update';
export const MESSAGE_SEEN_MESSAGE = '[MESSAGES] seen message';
export const MESSAGES_SEEN_REQUEST_START_ACTION = '[MESSAGES] start  request start';
export const MESSAGES_SEEN_REQUEST_SUCCESS_ACTION = '[MESSAGES] start  request success';

export const deleteMessageRequestStart = createAction(
  DELETE_MESSAGE_REQUEST_START_ACTION,
  props<{id: string}>()
);

export const deleteMessageRequestSuccess = createAction(
  DELETE_MESSAGE_REQUEST_SUCCESS_ACTION
);

export const deleteMessageRequestReject = createAction(
  DELETE_MESSAGE_REQUEST_REJECT_ACTION
);

export const sendNewMessageStart = createAction(
  SEND_NEW_MESSAGE_START,
  props<{ content: string, roomUUID: string, messageType: string, parentId?: string }>()
);
export const sendNewMessageSuccess = createAction(
  SEND_NEW_MESSAGE_SUCCESS_ACTION
);

export const onNewMessage = createAction(
  ON_NEW_MESSAGE,
  props<{ newMessage: any}>()
);

export const seenMessagesForRoomCounter = createAction(
  SEEN_MESSAGES_FOR_ROOM_COUNTER_ACTION,
  props<{id: string}>()
);

// export const setSeenMessagesCount = createAction(
//   SET_MESSAGES_COUNT_ACTION,
//   props<{count: number}>()
// );

export const messagesListRequestStart = createAction(
  MESSAGES_LIST_REQUEST_START_ACTION
);

export const messagesListRequestSuccess = createAction(
  MESSAGES_LIST_REQUEST_SUCCESS_ACTION,
  props<{payload:any}>()
);

export const messagesListRequestFail = createAction(
  MESSAGES_LIST_REQUEST_REJECT_ACTION
);

export const messageListFilterUpdate = createAction(
  MESSAGE_LIST_FILTER_UPDATE,
  props<{payload: any}>()
);
export const messageListFilterReset = createAction(
  MESSAGE_LIST_FILTER_RESET
);
export const messageListPaginationUpdate = createAction(
  MESSAGE_LIST_PAGINATION_UPDATE,
  props<{payload: any}>()

);
export const messageSeenMessage = createAction(
  MESSAGE_SEEN_MESSAGE,
  props<{roomId: string, seenMemberId: string, messageId: string}>()

);
export const messagesSeenRequestStart = createAction(
  MESSAGES_SEEN_REQUEST_START_ACTION,
  props<{id: string, roomId: string}>()
);

export const messagesSeenRequestSuccess = createAction(
  MESSAGES_SEEN_REQUEST_SUCCESS_ACTION,
);

