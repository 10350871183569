import {Injectable} from '@angular/core';
import {HttpService} from "@revolve-app/app/core/services/network/http/http.service";
import {TCreateAccountModel, TUpdateAccountModel} from "@revolve-app/app/core/metadata/types/account.models";
import {MicroService} from "@revolve/meta-data/metadata/enums/MicroService";
import {env} from "@revolve-app/env/env";

@Injectable()
export class MessageService{

  private baseUrl = `api/gateway/${MicroService.CHAT}/${env.backendVersion}`;

  constructor(private httpService: HttpService) {
  }

  list(queryParams: any){
    return this.httpService.get(`${this.baseUrl}/message/list`, {...queryParams} )
  }

  update(id: string, data: TUpdateAccountModel){
    return this.httpService.put(`${this.baseUrl}/message/${id}`, {...data})
  }

  delete(id: string){

    return this.httpService.delete(`${this.baseUrl}/message/${id}`)
  }

  create(data: any){
    return this.httpService.post(`${this.baseUrl}/message/new-message`, {...data}, {showSuccess: false})
  }
  seen(id: string){
    return this.httpService.post(`${this.baseUrl}/message/seen/${id}`, {}, {showSuccess: false})
  }
}
