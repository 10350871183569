@if (room){
<a class="flex flex-row py-4 px-2 items-center border-b-2" [ngClass]="{
' border-l-4 border-blue-400': isSelected
}">
  <div class="w-1/4">
    @if(room.imageUrl){
      <img
        [src]="room.imageUrl"
        class="object-cover h-12 w-12 rounded-full p-1"
        alt="Javascript indonesia"
      />
    } @else if (room.type === RoomType.WITH_PLATFORM_ADMIN && user?.role === UserRolesEnum.CUSTOMER){
      <img
        src="./assets/images/icons/chat-assistant.jpg"
        class="object-cover h-12 w-12 rounded-full p-1"
        alt="Javascript indonesia"
      />
    }@else if (room.type === RoomType.WITH_PLATFORM_ADMIN && user?.role !== UserRolesEnum.CUSTOMER){
      <img
        src="./assets/images/icons/chat-assistant.jpg"
        class="object-cover h-12 w-12 rounded-full p-1"
        alt="Javascript indonesia"
      />
    }

  </div>
  <div class="w-full ">
    <div class="text-lg font-semibold">
      @if (room.name){
        <span>{{ room.name }}</span>
      } @else if (room.type === RoomType.WITH_PLATFORM_ADMIN && (!user || user?.role === UserRolesEnum.CUSTOMER)){
        <span>WithAdmin</span>
      } @else if (room.type === RoomType.WITH_PLATFORM_ADMIN && user && user?.role !== UserRolesEnum.CUSTOMER){
        <span>{{ submittedUser?.roomName }}</span>
      }
    </div>
    <div class="flex justify-between">
      <span class="text-gray-500">{{ room.lastMessage?.content || ''}}</span>
      @if (localMember && localMember.notSeenMessagesCount){
        <span class="bg-red-500 w-7 h-7 rounded-full flex items-center justify-center text-white">{{ localMember.notSeenMessagesCount}}</span>
      } @else if (!localMember){
        <span class="bg-red-500  w-10 h-10 rounded-full flex items-center justify-center text-white">new</span>
      }

    </div>

  </div>
</a>
}
