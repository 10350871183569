<div class="bg mb-5">
  <div class="flex flex-col mt-5 px-2 h-72 overflow-y-auto	padding-1"  #messageContainer (scroll)="onScroll()">
    @for (message of $data; track $data) {
      <app-message-item [currentChatMember]="currentChatMember" [message]="message" [chatMembers] = "room.chatMembers"></app-message-item>
    }
    <div #scrollToBottom></div>
  </div>
  <div>
    <app-write-message-input [room]="room" ></app-write-message-input>
  </div>
</div>

