import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MessageBodyType} from "@revolve/meta-data/roots/chat-microservice/metadata";

@Injectable()
export class RoomsForm {
  constructor(private fb: FormBuilder) {}

  renameRoom(): FormGroup {
    return this.fb.group({
      status: ['', Validators.required]
    });
  }

  infoModal(): FormGroup {
    return this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      displayName: ['', [Validators.required]],
    });
  }

  newMessage(){
    return this.fb.group({
      content: ['', Validators.required],
      roomUUID: ['', Validators.required],
      messageType: [MessageBodyType.TEXT, Validators.required],
      parentId: [],
    });
  }

  searchRoom(){
    return this.fb.group({
      message: ['', Validators.required]
    });
  }



}
