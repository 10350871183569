import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {selectIsLoggedIn, selectMeAccount} from "@revolve-app/app/core/features/accounts/store/me/me.reducer";
import {selectSelectedRoom} from "@revolve-app/app/core/features/chat/store/rooms/rooms.reducer";
import {
  selectIpSession
} from "@revolve-app/app/core/features/platform-settings/store/platform-settings/platform-settings.reducer";
import {Store} from "@ngrx/store";
import {UserRolesEnum} from "@revolve/meta-data/metadata/enums/UserRole";
import {RoomType} from "@revolve/meta-data/roots/chat-microservice/metadata";

@Component({
  selector: 'app-chat-widget-header',
  standalone: true,
  templateUrl: './chat-widget-header.component.html'
})
export class ChatWidgetHeaderComponent implements OnInit, OnDestroy {
  @Input() forWidget:boolean = false;

  isLoggedInSelector$: any
  isMeUserSelector$: any
  roomSelector$: any
  currentIpSelector$: any
  linkToSubscribe: any = [];

  selectedRoom: any
  isLoggedIn: boolean = false
  ipSession: any
  localUser: any
  localMember: any = {}

  constructor(private store: Store) {
    this.isLoggedInSelector$ = this.store.select(selectIsLoggedIn)
    this.isMeUserSelector$ = this.store.select(selectMeAccount)
    this.roomSelector$ = this.store.select(selectSelectedRoom)
    this.currentIpSelector$ = this.store.select(selectIpSession)
    this.linkToSubscribe.push(this.roomSelector$.subscribe((data:any) => {
      this.selectedRoom = data
      if(this.selectedRoom){
        this.localMember = this.selectedRoom?.chatMembers?.find((member: any) => member.userUUID === this.localUser?._uuid)
        if(!this.localMember && !this.localUser){
          this.localMember = this.selectedRoom?.chatMembers.find((x: any) => x.ipUUID === this.ipSession?.id);
        }
      }

    }));

    this.linkToSubscribe.push(this.isLoggedInSelector$.subscribe((data:boolean) => {
      this.isLoggedIn = data
    }));
    this.linkToSubscribe.push(this.isMeUserSelector$.subscribe((data:boolean) => {
      this.localUser = data
      if(this.localUser){
        this.localMember = this.selectedRoom?.chatMembers?.find((member: any) => member.userUUID === this.localUser._uuid)
      }

    }));
    this.linkToSubscribe.push(this.currentIpSelector$.subscribe((data:any) => {
      this.ipSession = data
      if(!this.localUser && this.selectedRoom){
        this.localMember = this.selectedRoom.chatMembers.find((x: any) => x.ipUUID === this.ipSession?.id);
      }
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    for (let i = 0; i < this.linkToSubscribe.length; i++) {
      this.linkToSubscribe[i].unsubscribe();
    }
  }

  protected readonly UserRolesEnum = UserRolesEnum;
  protected readonly RoomType = RoomType;
}
