import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {Router} from "@angular/router";
import {catchError, map, mergeMap, tap, withLatestFrom} from "rxjs/operators";

import * as RoomActions from './rooms.actions';
import {RoomsService} from "@revolve-app/app/core/features/chat/services/rooms.service";
import {of} from "rxjs";
import {messageListFilterUpdate} from "@revolve-app/app/core/features/chat/store/message/message.actions";
import {
  socketJoinRoom
} from "@revolve-app/app/core/features/socket/store/socket/socket.actions";
import {SocketRooms} from "@revolve/meta-data/metadata/enums/SocketRooms";
import {paginationQueryWrapper} from "@revolve-app/app/core/utils/utils";
import {selectRoomFilter, selectRoomPagination} from "@revolve-app/app/core/features/chat/store/rooms/rooms.reducer";

@Injectable()
export class RoomsEffect {

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private roomService: RoomsService
  ) {}

  list$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomActions.roomsListRequestStart),
      withLatestFrom(this.store.pipe(select(selectRoomFilter)), this.store.pipe(select(selectRoomPagination))),
      mergeMap(([action, filter, pagination]) => {
          let queryObj= paginationQueryWrapper(pagination, filter);
          return this.roomService.list(queryObj)
            .pipe(
              map(payload => {
                return RoomActions.roomsListRequestSuccess({payload})
              }),
              catchError(error => {
                return of(RoomActions.roomsListRequestStartFail())
              })
            )
        }
      )
    )
  });
  listSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(RoomActions.roomsListRequestSuccess),
        tap((action) => {
          }
        )
      ),
    { dispatch: false } // Set dispatch to false to prevent this effect from dispatching actions

  );
  openRoomRequestStart = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomActions.openRoomRequestStart),
      mergeMap((action) => {
          return this.roomService.openRoom(action.data)
            .pipe(
              map(payload => {
                this.store.dispatch(socketJoinRoom({roomName: SocketRooms.CHAT, roomId: payload._uuid}));
                this.store.dispatch(messageListFilterUpdate({payload: {roomUUID: payload._uuid}}))
                return RoomActions.openRoomRequestSuccess({ payload })
              })
            )
        }
      )
    )
  });

  openRoomByIdRequestStart = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomActions.openRoomByIdRequestStart),
      mergeMap((action) => {
          return this.roomService.getById(action.id)
            .pipe(
              map(payload => {
                this.store.dispatch(socketJoinRoom({roomName: SocketRooms.CHAT, roomId: payload._uuid}));
                this.store.dispatch(messageListFilterUpdate({payload: {roomUUID: payload._uuid}}))
                return RoomActions.openRoomRequestSuccess({ payload })
              })
            )
        }
      )
    )
  });
}
