import { NgModule } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import {messageReducer, MESSAGES_FEATURE_NAME} from "@revolve-app/app/core/features/chat/store/message/message.reducer";
import {MessageEffect} from "@revolve-app/app/core/features/chat/store/message/message.effect";
import {MessageService} from "@revolve-app/app/core/features/chat/services/message.service";

@NgModule({
  imports: [
  ],
  providers: [
    MessageService,
    provideState(MESSAGES_FEATURE_NAME, messageReducer),
    provideEffects(MessageEffect)
  ]
})
export class MessageModule { }
