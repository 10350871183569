import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {Router} from "@angular/router";
import * as MessageActions from "@revolve-app/app/core/features/chat/store/message/message.actions";
import {catchError, map, mergeMap, tap, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs";
import {MessageService} from "@revolve-app/app/core/features/chat/services/message.service";
import {paginationQueryWrapper} from "@revolve-app/app/core/utils/utils";
import {
  selectMessagesFilter,
  selectMessagesPagination
} from "@revolve-app/app/core/features/chat/store/message/message.reducer";
import {
  changeRoomSeenMessagesCount,
  changeSeenMessagesCount
} from "@revolve-app/app/core/features/chat/store/rooms/rooms.actions";
import {changeGlobalMessagesCount} from "@revolve-app/app/core/features/accounts/store/me/me.actions";

@Injectable()
export class MessageEffect {

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private messageService: MessageService
  ) {}
  list$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.messagesListRequestStart),
      withLatestFrom(this.store.pipe(select(selectMessagesFilter)), this.store.pipe(select(selectMessagesPagination))),
      mergeMap(([action, filter, pagination]) => {
          let queryObj= paginationQueryWrapper(pagination, filter);
          return this.messageService.list(queryObj)
            .pipe(
              map((payload: any) => {
                if(payload && payload.seenMessagesCount){
                  this.store.dispatch(changeRoomSeenMessagesCount({count: -payload.seenMessagesCount, chatMemberId: payload.chatMemberId, roomId: filter.roomUUID}))
                  this.store.dispatch(changeGlobalMessagesCount({count: -payload.seenMessagesCount, chatMemberId: payload.chatMemberId, roomId: filter.roomUUID}))
                }
                return MessageActions.messagesListRequestSuccess({ payload })
              }),
              catchError(error => {
                return of(MessageActions.messagesListRequestFail())
              })
            )
        }
      )
    )
  });
  listSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(MessageActions.messagesListRequestSuccess),
        tap((action) => {
          }
        )
      ),
    { dispatch: false } // Set dispatch to false to prevent this effect from dispatching actions

  );


  onFilterChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.messageListFilterUpdate),
      map(() => MessageActions.messagesListRequestStart())
    );
  });


  openRoomByIdRequestStart = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.sendNewMessageStart),
      mergeMap((action) => {
          return this.messageService.create({...action, type: action.messageType})
            .pipe(
              map(payload => {
                return MessageActions.sendNewMessageSuccess()
              })
            )
        }
      )
    )
  });
  seenMessageRequestStart = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.messagesSeenRequestStart),
      mergeMap((action) => {
          return this.messageService.seen(action.id)
            .pipe(
              map(payload => {
                this.store.dispatch(changeGlobalMessagesCount({count: -1, chatMemberId: '', roomId: action.roomId}))
                return MessageActions.messagesSeenRequestSuccess()
              })
            )
        }
      )
    )
  });

}
