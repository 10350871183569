import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {RoomsForm} from "@revolve-app/app/core/features/chat/forms/rooms.form";
import {Store} from "@ngrx/store";
import {sendNewMessageStart} from "@revolve-app/app/core/features/chat/store/message/message.actions";

@Component({
  selector: 'app-write-message-input',
  standalone: true,
  templateUrl: './write-message-input.component.html',
  imports: [
    ReactiveFormsModule,
    ],
  providers: [
    RoomsForm
  ],
})
export class WriteMessageInputComponent implements OnInit, OnChanges{
  replyForm: FormGroup;
  @Input() room: any;

  constructor(private form: RoomsForm,
              private store: Store) {
    this.replyForm = this.form.newMessage()
  }

  ngOnInit(): void {
    if(this.room){
      this.replyForm.controls['roomUUID'].patchValue(this.room._uuid)
    }
  }
  sendMessage() {
    console.log('Message sent:', this.replyForm.value);
    if (this.replyForm.valid) {
      this.store.dispatch(sendNewMessageStart(this.replyForm.value))
      this.replyForm.controls['content'].patchValue('');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['room'] && changes['room'].currentValue) {
      this.replyForm.controls['roomUUID'].patchValue(changes['room'].currentValue?._uuid)
    }
  }

}
