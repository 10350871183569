import { NgModule } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import {
  CHAT_ROOMS_FEATURE_NAME,
  roomsReducer
} from "@revolve-app/app/core/features/chat/store/rooms/rooms.reducer";
import {RoomsEffect} from "@revolve-app/app/core/features/chat/store/rooms/rooms.effect";
import {RoomsService} from "@revolve-app/app/core/features/chat/services/rooms.service";

@NgModule({
  imports: [
  ],
  providers: [
    RoomsService,
    provideState(CHAT_ROOMS_FEATURE_NAME, roomsReducer),
    provideEffects(RoomsEffect)
  ]
})
export class RoomsModule { }
