
export enum MessageBodyType {
    TEXT = 'text',
    IMAGE = 'image',
    FILE = 'file',
}

export enum MessageStatus {
    NOT_SEEN = 0,
    SEEN = 1,
    EDITED= 2,
    DELETED= 99,
}
export enum RoomStatus {
    EMPTY = 0,
    ACTIVE = 1,
    BLOCKED = 2,
    DELETED = 99
}
export enum RoomType {
    INDIVIDUAL = 'individual',
    GROUP = 'group',
    WITH_PLATFORM_ADMIN = 'withPlatformAdmin',
    WITH_SUPER_ADMIN = 'withSuperAdmin',
    WITH_EVENT_ORGANIZATION = 'withEventOrganization'
}

export interface RoomData {
    _uuid?: string
    chatMembers?: string[]
    creator?: string
    name?: string
    imageUrl?: string
    creatorUserUUID?: string
    type: RoomType
    platformUUID: string
}

export interface MemberData {
    _uuid?:string,
    userUUID?:string,
    firstname: string,
    lastname?: string,
    email: string,
    imageUrl?: string,
    role?: 'customer' | 'admin'
}
export enum ChatMemberRole {
    CUSTOMER = 'customer',
    ADMIN = 'admin'
}