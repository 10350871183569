import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-contact',
  standalone: true,
  templateUrl: './search-contact.component.html'
})
export class SearchContactComponent implements OnInit{

  constructor() {
  }

  ngOnInit(): void {
  }
}
