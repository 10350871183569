import {Injectable, OnDestroy} from '@angular/core';
import {HttpService} from "@revolve-app/app/core/services/network/http/http.service";
import {TCreateAccountModel, TUpdateAccountModel} from "@revolve-app/app/core/metadata/types/account.models";
import {Observable} from "rxjs";
import {OpenRoomResponseInterface} from "@revolve/meta-data/roots/chat-microservice/room/response";
import {env} from "@revolve-app/env/env";
import {MicroService} from "@revolve/meta-data/metadata/enums/MicroService";
import {OpenRoomInterface} from "@revolve/meta-data/roots/chat-microservice/room/request";

@Injectable()
export class RoomsService{

  private baseUrl = `api/gateway/${MicroService.CHAT}/${env.backendVersion}`;

  constructor(private httpService: HttpService) {
  }

  list(queryParams: any){
    return this.httpService.get(`${this.baseUrl}/room/list`, {...queryParams})
  }

  getById(id:string): Observable<OpenRoomResponseInterface>{
    return this.httpService.get(`${this.baseUrl}/room/${id}`, {})
  }

  update(id: string, data: TUpdateAccountModel){
    return this.httpService.put(`${this.baseUrl}/room/${id}`, {...data})
  }

  delete(id: string){

    return this.httpService.delete(`${this.baseUrl}/room/${id}`)
  }

  openRoom(data: OpenRoomInterface): Observable<OpenRoomResponseInterface>{
    return this.httpService.post(`${this.baseUrl}/room/open-room`, {...data})
  }
}
