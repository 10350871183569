import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {OpenRoomResponseInterface} from "@revolve/meta-data/roots/chat-microservice/room/response";
import * as RoomsActions from "./rooms.actions";
import {initialPaginationState, PaginationType} from "@revolve-app/app/core/utils/pagination";


export const CHAT_ROOMS_FEATURE_NAME = 'ROOMS FEATURE';

export interface RoomsState {
  rooms: OpenRoomResponseInterface[],
  searchKey?: string | null,
  filters: any,
  selectedRoom?: OpenRoomResponseInterface | null,
  totalMessages: number,
  loading?: boolean,
  roomId: string | null,
  offset: number,
  limit: number,
  pagination: PaginationType,

}
export const roomInitialSettings: RoomsState = {
  rooms: [],
  filters: { email: '' },
  searchKey: null,
  selectedRoom: null,
  roomId: null,
  totalMessages: 0,
  pagination: {...initialPaginationState},
  loading: false,
  offset: 0,
  limit: 10
};

export const roomsReducer = createReducer(
  roomInitialSettings,
  on(RoomsActions.roomsListRequestStart, (state) =>({...state, loading: true, offset: state.offset +state.limit}) ),
  on(RoomsActions.roomsListRequestSuccess, (state, {payload}) =>{
    return{
    ...state,
    loading: false,
    rooms: [...payload.data],
    pagination: {
      order: payload.meta.sortBy,
      sortBy: payload.meta.sortBy[0][0] + ':' + payload.meta.sortBy[0][1],
      limit: payload.meta.itemsPerPage,
      offset: payload.meta.itemsPerPage * (payload.meta.currentPage - 1),
      page: payload.meta.currentPage,
      totalPages: payload.meta.totalPages,
      count: payload.meta.totalItems
    },
    totalCount: payload.meta.totalItems

  }}),
  on(RoomsActions.roomsListRequestFail, (state) =>({...state, loading: false}) ),
  on(RoomsActions.openRoomRequestSuccess, (state, { payload }) => {
    let index = state.rooms.findIndex(room => room.id === payload.id);

    // Create a new rooms array
    let updatedRooms;
    if (index === -1) {
      // If the room does not exist, add the new room to the beginning of the array
      updatedRooms = [payload, ...state.rooms];
    } else {
      // If the room exists, create a new array with the updated room
      updatedRooms = [...state.rooms];
      updatedRooms[index] = payload;
    }

    // Return the new state object
    return { ...state, rooms: updatedRooms, selectedRoom: payload };
  }),
  on(RoomsActions.deleteRoomRequestStart, (state, payload) =>({...state, loading: true, roomId: payload.id}) ),
  on(RoomsActions.deleteRoomRequestSuccess, (state) =>({...state, loading: false}) ),
  on(RoomsActions.deleteRoomRequestReject, (state) =>({...state, loading: false}) ),
  on(RoomsActions.roomsResetSearch, (state) =>({...state, loading: false, searchKey: null}) ),
  on(RoomsActions.roomsSearchSetSearch, (state, payload) =>({...state, loading: false, searchKey: payload.key}) ),
  on(RoomsActions.changeSeenMessagesCount, (state, payload) =>{
    debugger
    return{...state, loading: false, totalMessages:  state.totalMessages + payload.count}} ),
  on(RoomsActions.roomsNewMessage, (state, payload) => {
    const index = state.rooms.findIndex((room: any) => room._uuid === payload.roomId);
    if (index >= 0) {
      const updatedRooms = state.rooms.slice(); // Create a shallow copy of the rooms array
      updatedRooms[index] = {
        ...updatedRooms[index],
        lastMessage: payload.newMessage
      };
      const memberIndex = updatedRooms[index].chatMembers.findIndex((member: any) => member._uuid === payload.chatMemberId);
      if (memberIndex >= 0) {
        const updatedChatMembers = updatedRooms[index].chatMembers.slice(); // Create a shallow copy of chatMembers array
        updatedChatMembers[memberIndex] = {
          ...updatedChatMembers[memberIndex],
          notSeenMessagesCount: (updatedChatMembers[memberIndex].notSeenMessagesCount || 0) + payload.count
        };
        updatedRooms[index] = {
          ...updatedRooms[index],
          chatMembers: updatedChatMembers
        };
      }
      return {
        ...state,
        rooms: updatedRooms
      };
    } else {
      return state;
    }
  }),
  on(RoomsActions.onUpdateRoom, (state, payload) => {
      let index = state.rooms.findIndex((room: any) => room._uuid === payload.room._uuid);
      if (index >= 0) {
        const updatedRooms = [...state.rooms];
        updatedRooms[index] = payload.room;
        const updatedSelectedRoom = state.selectedRoom?._uuid === payload.room._uuid ? payload.room : state.selectedRoom;
        return {
          ...state,
          rooms: updatedRooms,
          selectedRoom: updatedSelectedRoom
        };
      } else {
        return state;
      }
  }
  ),
  on(RoomsActions.roomSeenMessage, (state, payload) => {
    const index = state.rooms.findIndex((room: any) => room._uuid === payload.roomId);

    if (index >= 0) {
      const updatedRooms = [...state.rooms];
      const room = { ...updatedRooms[index] };
      const chatMembers = [...room.chatMembers];
      const memberIndex = chatMembers.findIndex((x: any) => x._uuid === payload.seenMemberId);

      if (memberIndex >= 0) {
        chatMembers[memberIndex] = {
          ...chatMembers[memberIndex],
          notSeenMessagesCount: chatMembers[memberIndex].notSeenMessagesCount - 1
        };

        room.chatMembers = chatMembers;
        updatedRooms[index] = room;
      }

      return {
        ...state,
        rooms: updatedRooms
      };
    }

    return state;
  }),
  on(RoomsActions.onAddRoom, (state: RoomsState, {payload} ) => {
    let updatedRooms = [payload, ...state.rooms];

    debugger
    // Return the new state object
    return {
      ...state,
      rooms: updatedRooms
    };
  }),
  on(RoomsActions.changeRoomSeenMessagesCount, (state: RoomsState, payload) => {
    const roomIndex = state.rooms.findIndex((room: any) => room._uuid === payload.roomId);

    if (roomIndex >= 0) {
      // Clone the rooms array
      const updatedRooms = [...state.rooms];
      // Clone the room object to avoid direct state mutation
      const updatedRoom = { ...updatedRooms[roomIndex] };

      const memberIndex = updatedRoom.chatMembers.findIndex((member: any) => member._uuid === payload.chatMemberId);

      if (memberIndex >= 0) {
        // Clone the chatMembers array
        const updatedChatMembers = [...updatedRoom.chatMembers];
        // Clone the chatMember object
        const updatedChatMember = { ...updatedChatMembers[memberIndex] };

        // Update the notSeenMessagesCount
        updatedChatMember.notSeenMessagesCount += payload.count;
        updatedChatMembers[memberIndex] = updatedChatMember;
        updatedRoom.chatMembers = updatedChatMembers;
        updatedRooms[roomIndex] = updatedRoom;
      }

      let updatedSelectedRoom = state.selectedRoom;
      if (state.selectedRoom?._uuid === payload.roomId) {
        const selectedRoomMemberIndex = state.selectedRoom.chatMembers.findIndex((member: any) => member._uuid === payload.chatMemberId);

        if (selectedRoomMemberIndex >= 0) {
          // Clone the selectedRoom object
          updatedSelectedRoom = { ...state.selectedRoom };
          // Clone the chatMembers array of the selectedRoom
          const updatedSelectedRoomChatMembers = [...updatedSelectedRoom.chatMembers];
          // Clone the chatMember object
          const updatedSelectedRoomChatMember = { ...updatedSelectedRoomChatMembers[selectedRoomMemberIndex] };

          // Update the notSeenMessagesCount
          updatedSelectedRoomChatMember.notSeenMessagesCount += payload.count;
          updatedSelectedRoomChatMembers[selectedRoomMemberIndex] = updatedSelectedRoomChatMember;
          updatedSelectedRoom.chatMembers = updatedSelectedRoomChatMembers;
        }
      }

      return {
        ...state,
        rooms: updatedRooms,
        selectedRoom: updatedSelectedRoom,
      };
    }

    return state;
  }),
  on(RoomsActions.seenMessagesForRoomCounter, (state, payload) =>({...state, loading: false}) ),
  on(RoomsActions.roomListFilterUpdate, (state, { payload }) => ({ ...state, filters: {...state.filters, ...payload }})),
  on(RoomsActions.roomListPaginationUpdate, (state, { payload }) =>  ({ ...state, pagination: {...state.pagination, ...payload }})),

);

export function rooms(state: RoomsState , action: Action) {
  return roomsReducer(state, action);
}

export const selectRoomsState = createFeatureSelector<RoomsState>(CHAT_ROOMS_FEATURE_NAME);

export const selectChatRooms = createSelector(
  selectRoomsState,
  (state: RoomsState) => state.rooms
);
export const selectSearchKey = createSelector(
  selectRoomsState,
  (state: RoomsState) => state.searchKey
);
export const selectSelectedRoom = createSelector(
  selectRoomsState,
  (state: RoomsState) => state.selectedRoom
);
export const selectTotalMessages = createSelector(
  selectRoomsState,
  (state: RoomsState) => state?.totalMessages
);
export const selectLoading = createSelector(
  selectRoomsState,
  (state: RoomsState) => state?.loading
);

export const selectRoomFilter = createSelector(
  selectRoomsState,
  (state: RoomsState) => state.filters
);
export const selectRoomPagination = createSelector(
  selectRoomsState,
  (state: RoomsState) => state.pagination
);

