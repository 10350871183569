import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgClass} from "@angular/common";
import {ChatMemberRole, RoomType} from "@revolve/meta-data/roots/chat-microservice/metadata";
import {UserRolesEnum} from "@revolve-app/app/core/metadata/enums/account/user-role.enum";

@Component({
  selector: 'app-contact-item',
  standalone: true,
  templateUrl: './contact-item.component.html',
  imports: [
    NgClass
  ]
})
export class ContactItemComponent implements OnInit, OnChanges{
  @Input() isSelected = false;
  @Input() room: any = null;
  @Input() user: any = null;
  @Input() ipSession: any = null;
  localMember: any
  submittedUser: any = {};
  constructor() {
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {

      if(changes["user"]) {
        this.localMember = this.room?.chatMembers?.find((member: any) => member.userUUID === changes["user"].currentValue?._uuid)
        if(this.room.type === RoomType.WITH_PLATFORM_ADMIN && changes["user"].currentValue?.role !== UserRolesEnum.CUSTOMER){
          this.submittedUser = this.room?.chatMembers?.find((member: any) => member.role === ChatMemberRole.CUSTOMER)
        }
      }
      if(changes["room"]) {
        this.localMember = changes["room"].currentValue?.chatMembers?.find((member: any) => member.userUUID === this.user?._uuid)
        if(!this.localMember && !this.user){
          this.localMember = this.room.chatMembers.find((x: any) => x.ipUUID === this.ipSession?.id);
        }
        if(changes["room"].currentValue.type === RoomType.WITH_PLATFORM_ADMIN && this.user?.role !== UserRolesEnum.CUSTOMER){
          this.submittedUser = changes["room"].currentValue?.chatMembers?.find((member: any) => member.role === ChatMemberRole.CUSTOMER)
        }
      }
      if (changes["ipSession"]) {
        if(!this.user){
          this.localMember = this.room.chatMembers.find((x: any) => x.ipUUID === changes["ipSession"].currentValue?.id);
        }
      }
  }

  protected readonly RoomType = RoomType;

  protected readonly UserRolesEnum = UserRolesEnum;
}
