<div class="px-5 py-5 flex justify-between items-center bg-dark border-b-2 border-dark">
  @if (selectedRoom){
    <div class="flex justify-center items-center ">
      <div class="w-100px p-1">
        @if(selectedRoom.imageUrl){
          <img
            [src]="selectedRoom.imageUrl"
            class="object-cover h-12 w-12 rounded-full p-1"
            alt="Javascript indonesia"
          />
        } @else if (selectedRoom.type === RoomType.WITH_PLATFORM_ADMIN && localUser?.role === UserRolesEnum.CUSTOMER){
          <img
            src="./assets/images/icons/user_chat.png"
            class="object-cover h-12 w-12 rounded-full p-1"
            alt="Javascript indonesia"
          />
        }@else if (selectedRoom.type === RoomType.WITH_PLATFORM_ADMIN && localUser?.role !== UserRolesEnum.CUSTOMER){
          <img
            src="./assets/images/icons/chat-assistant.jpg"
            class="object-cover h-12 w-12 rounded-full p-1"
            alt="Javascript indonesia"
          />
        }
      </div>
      <div>
        <span class="text-white">
          {{localMember.roomName}}
        </span>
      </div>
    </div>
  }


<!--  <a class="text-black lg:hidden block text-32">-->
<!--    ✕-->
<!--  </a>-->
</div>
