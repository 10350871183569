<div class="flex mb-4"
[ngClass]="{
  'justify-start': !fromMe,
  'justify-end': fromMe
}">
  <img
    *ngIf="!fromMe && sender"
    [src]="sender.imageUrl? sender.imageUrl:sender.role === ChatMemberRole.ADMIN?'./assets/images/icons/chat-assistant.jpg': './assets/images/icons/user_chat.png'"
    class="object-cover h-8 w-8 rounded-full"
    alt="my profile image 3"
  />
  <div
    class="py-3 px-4   rounded-tr-xl text-white"
    [ngClass]="{
    'bg-blue-400 rounded-bl-3xl mr-2  rounded-tl-3xl': fromMe,
    'bg-gray-400 rounded-br-3xl ml-2  rounded-tl-3xl': !fromMe
    }"
  >
    {{message.content}}
  </div>
  <img
    *ngIf="fromMe && sender"
    [src]="sender.imageUrl? sender.imageUrl:sender.role === ChatMemberRole.ADMIN?'./assets/images/icons/chat-assistant.jpg': './assets/images/icons/user_chat.png'"
    class="object-cover h-8 w-8 rounded-full"
    alt="opponent profile image"
  />
</div>
