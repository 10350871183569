import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as MessageActions from "./message.actions";
import {MessageResponseInterface} from "@revolve/meta-data/roots/chat-microservice/message/response";
import {AccountState, selectAccountState} from "@revolve-app/app/core/features/accounts/store/account/account.reducer";
import {initialPaginationState, PaginationType} from "@revolve-app/app/core/utils/pagination";
import * as AccountActions from "@revolve-app/app/core/features/accounts/store/account/account.actions";
import {messageSeenMessage, sendNewMessageStart} from "./message.actions";

export const MESSAGES_FEATURE_NAME = 'MESSAGES FEATURE';
interface MessageState{
  messages: MessageResponseInterface[],
  offset: number,
  limit: number,
  filters: any,
  loading?: boolean,
  roomId: string | null,
  totalMessages: number,
  pagination: PaginationType,
}

export const messageInitialSettings: MessageState = {
  messages: [],
  roomId: null,
  totalMessages: 0,
  filters: {},
  loading: false,
  offset: 0,
  limit: 10,
  pagination: {...initialPaginationState}
};

export const messageReducer = createReducer(
  messageInitialSettings,
  on(MessageActions.messagesListRequestStart, (state) =>({...state, loading: true, offset: state.offset +state.limit}) ),
  on(MessageActions.messagesListRequestSuccess, (state, {payload}) =>{
    return{
      ...state,
      loading: false,
      messages: [...payload.data],
      pagination: {
        order: payload.meta.sortBy,
        sortBy: payload.meta.sortBy[0][0] + ':' + payload.meta.sortBy[0][1],
        limit: payload.meta.itemsPerPage,
        offset: payload.meta.itemsPerPage * (payload.meta.currentPage - 1),
        page: payload.meta.currentPage,
        totalPages: payload.meta.totalPages,
        count: payload.meta.totalItems
      },
      totalCount: payload.meta.totalItems
    }

  } ),
  on(MessageActions.messagesListRequestFail, (state) =>({...state, loading: false}) ),
  on(MessageActions.deleteMessageRequestStart, (state, payload) =>({...state, loading: true, roomId: payload.id}) ),
  on(MessageActions.deleteMessageRequestSuccess, (state) =>({...state, loading: false}) ),
  on(MessageActions.deleteMessageRequestReject, (state) =>({...state, loading: false}) ),
  on(MessageActions.sendNewMessageStart, (state) =>({...state, loading: false}) ),
  on(MessageActions.seenMessagesForRoomCounter, (state, payload) =>({...state, loading: false}) ),
  on(MessageActions.messageListFilterUpdate, (state, { payload }) => ({ ...state, filters: {...state.filters, ...payload }})),
  on(MessageActions.messageListPaginationUpdate, (state, { payload }) =>  ({ ...state, pagination: {...state.pagination, ...payload }})),
  on(MessageActions.onNewMessage, (state, { newMessage }) =>  {
    return {...state,    messages: [...[newMessage], ...state.messages ],}
  }),
  on(MessageActions.messageSeenMessage, (state,  payload) =>  {
    let index = state.messages.findIndex((x) => x._uuid === payload.messageId);

    if (index >= 0) {
      const updatedMessages = [...state.messages];
      updatedMessages[index] = {
        ...updatedMessages[index],
        seenUserIds: [...updatedMessages[index].seenUserIds, payload.seenMemberId]
      };

      return { ...state, messages: updatedMessages };
    }

    return state;
  }),

);

export function messages(state: MessageState , action: Action) {
  return messageReducer(state, action);
}

export const selectMessageState = createFeatureSelector<MessageState>(MESSAGES_FEATURE_NAME);

export const selectMessages = createSelector(
  selectMessageState,
  (state: MessageState) => state.messages
);


export const selectTotalMessages = createSelector(
  selectMessageState,
  (state: MessageState) => state?.totalMessages
);
export const selectLoading = createSelector(
  selectMessageState,
  (state: MessageState) => state?.loading
);
export const selectMessagesFilter = createSelector(
  selectMessageState,
  (state: MessageState) => state.filters
);
export const selectMessagesPagination = createSelector(
  selectMessageState,
  (state: MessageState) => state.pagination
);
