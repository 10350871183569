import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chat-header',
  standalone: true,
  templateUrl: './chat-header.component.html'
})
export class ChatHeaderComponent implements OnInit{
  @Input() forWidget:boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }
}
