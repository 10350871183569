import {Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {MessageItemComponent} from "../message-item/message-item.component";
import {ChatWidgetHeaderComponent} from "@revolve-app/app/theme/components/chat-components/partrials/header/chat-widget-header/chat-widget-header.component";
import {
  WriteMessageInputComponent
} from "@revolve-app/app/theme/components/chat-components/partrials/write-message-input/write-message-input.component";
import {Store} from "@ngrx/store";
import {
  openRoomByIdRequestStart,
  roomsListRequestStart
} from "@revolve-app/app/core/features/chat/store/rooms/rooms.actions";
import {selectSelectedRoom} from "@revolve-app/app/core/features/chat/store/rooms/rooms.reducer";
import {
  messageListFilterUpdate,
  messageListPaginationUpdate,
  messagesListRequestStart
} from "@revolve-app/app/core/features/chat/store/message/message.actions";
import {
  selectMessages,
  selectMessagesPagination
} from "@revolve-app/app/core/features/chat/store/message/message.reducer";
import {MessageModule} from "@revolve-app/app/core/features/chat/store/message/message.module";
import {Pagination} from "@revolve-app/app/core/utils/pagination";
import { selectMeAccount} from "@revolve-app/app/core/features/accounts/store/me/me.reducer";
import {
  ContactItemComponent
} from "@revolve-app/app/theme/components/chat-components/partrials/contact-item/contact-item.component";
import {RoomsModule} from "@revolve-app/app/core/features/chat/store/rooms/rooms.module";
import {
  SearchContactComponent
} from "@revolve-app/app/theme/components/chat-components/partrials/search-contact/search-contact.component";
import {
  ChatHeaderComponent
} from "@revolve-app/app/theme/components/chat-components/partrials/header/chat-header/chat-header.component";
import {ButtonComponent} from "@revolve-app/app/shared/forms/buttons/button/button.component";
import {InputComponent} from "@revolve-app/app/shared/forms/text-inputs/input/input.component";
import {ReactiveFormsModule} from "@angular/forms";
import {
  selectIpSession
} from "@revolve-app/app/core/features/platform-settings/store/platform-settings/platform-settings.reducer";
import {socketLeaveRoom} from "@revolve-app/app/core/features/socket/store/socket/socket.actions";
import {SocketRooms} from "@revolve/meta-data/metadata/enums/SocketRooms";

@Component({
  selector: 'app-conversation-layout',
  standalone: true,
  templateUrl: './conversation-layout.component.html',
  imports: [
    MessageItemComponent,
    WriteMessageInputComponent,
    ChatWidgetHeaderComponent,
    WriteMessageInputComponent,
    MessageModule,
    MessageItemComponent,
    ContactItemComponent,
    RoomsModule,
    SearchContactComponent,
    WriteMessageInputComponent,
    ChatWidgetHeaderComponent,
    ChatHeaderComponent,
    ButtonComponent,
    InputComponent,
    ReactiveFormsModule
  ]
})
export class ConversationLayoutComponent extends Pagination implements OnInit, OnDestroy{
  room: any = null
  roomSelector$: any;
  currentUser: any
  messagesSelector$: any;
  currentIpSelector$: any;
  currentUserSelector$: any;
  currentChatMember: any
  linksToSubscribe: any[] = []
  ipSession: any = {}
  @ViewChild('messageContainer') messageContainer!: ElementRef;
  @ViewChild('scrollToBottom') scrollToBottom!: ElementRef;
  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    super(messageListPaginationUpdate, messagesListRequestStart, selectMessagesPagination, platformId, false);
    this.init()
    this.roomSelector$ = this.store.select(selectSelectedRoom)
    this.messagesSelector$ = this.store.select(selectMessages)
    this.currentUserSelector$ = this.store.select(selectMeAccount)
    this.currentIpSelector$ = this.store.select(selectIpSession)
    this.linksToSubscribe.push(this.roomSelector$.subscribe((data:any) => {
      this.room = data
      if(this.room){
        if(this.currentUser){
          this.currentChatMember = this.room.chatMembers.find((x: any) => x.userUUID === this.currentUser?._uuid);
        }else {
          this.currentChatMember = this.room.chatMembers.find((x: any) => x.ipUUID === this.ipSession?.id);
        }
      }
    }));
    this.linksToSubscribe.push(this.currentUserSelector$.subscribe((data:any) => {
      this.currentUser = data
      if(this.room && this.currentUser){
        this.currentChatMember = this.room.chatMembers.find((x: any) => x.userUUID === this.currentUser?._uuid);
      }
    }));

    this.linksToSubscribe.push(this.currentIpSelector$.subscribe((data:any) => {
      this.ipSession = data
      if(!this.currentChatMember && this.ipSession && this.room && !this.currentUser){
        this.currentChatMember = this.room.chatMembers.find((x: any) => x.ipUUID === this.ipSession?.id);
      }
    }));


    this.linksToSubscribe.push(this.messagesSelector$.subscribe((data:any) => {
      if (!this.$data) {
        this.$data = [];
      }
      const newData = [...data];
      newData.reverse();
      if(!this.pagination.page ||  this.pagination.page === 1){
        this.$data = newData
        setTimeout(() => {
          this.makeScrollToBottom();
        }, 10)
      }else {
        this.$data = [...newData, ...this.$data]
      }


    }));
  }

  ngOnInit(): void {

  }

  ngAfterContentInit(): void {
    this.makeScrollToBottom();
  }


  makeScrollToBottom(): void {

    const container = this.messageContainer?.nativeElement;
    if (container) {
      const scrollToBottom = this.scrollToBottom.nativeElement;
      container.scrollTop = scrollToBottom.offsetTop;
    }

  }

  onScroll(){
    const container = this.messageContainer.nativeElement;
    if (container.scrollTop < 10) {
      this.nextPage()
    }
  }
  ngOnDestroy() {
    if(this.room){
      this.store.dispatch(socketLeaveRoom({roomName: SocketRooms.CHAT, roomId: this.room._uuid}))
    }
    for (let i = 0; i < this.linksToSubscribe.length; i++) {
      this.linksToSubscribe[i].unsubscribe();
    }
    this.linksToSubscribe = []
  }

}
